/* You can add global styles to this file, and also import other style files */

fa-icon svg {
  width: 1em;
  height: 1em;
}

.loader-ieonly {
  display: none;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .loader-ieonly {
    display: block;
  }

  .svg-inline--fa {
    width: 1em;
    height: 1em;
  }
}

.highlight {
background-color: #f8f9fa;
padding: 1.5rem;
margin-bottom:0;
}

.alert-status {
  margin-bottom: 0;
}

.consent-alert {
  float:left;
  position: fixed;
  bottom:0;
  z-index:999;
}

@media (min-width: 1280px) {
  .container-fluid {
      min-height:50vh;
  }
}

.loader {
  background:white;
  height: 100%;
}

.app-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 100px;
}


.app-loading .spinner {
  height: 75px;
  width: 75px;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (min-width: 576px) {
  .app-loading .spinner {
      height: 200px;
      width: 200px;
      margin-top: 200px;
  }
}


.app-loading .spinner .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #ddd;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}